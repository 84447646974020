import request from 'hb-redux-store/util/request';

export const getPaymentSummaryApi = ({isPartialPayment=true}) =>
  request.get(`/api/payment/v3/details?isPartialPayment=${isPartialPayment}`);

export const getPaymentMethodsApi = ({ halalboxMoney = false }) =>
  request.get(`/api/payment/v3/methods?halalboxMoney=${halalboxMoney}`);

export const getPGCustomerIdApi = () => request.post('/api/payment/customer');

export const postDeliveryAddressApi = data =>
  request.post('/api/updateDeliveryAddress', data);

export const addNewDeliveryAddressApi = data =>
  request.post('/api/addNewDeliveryAddress', data);

export const updateDeliveryAddressApi = data =>
  request.post('/api/updateDeliveryAddress', data);

export const subscribeNewUserApi = data =>
  request.post('/api/customerSubscription', data);

export const validateUserLocationApi = data =>
  request.post('/api/validateSelectedLocation', data);

export const validateSelectedLocationForNudgeApi = data =>
  request.post('/api/validateSelectedLocationOnAddressScreen', data);

export const removeShoppingCartApi = data =>
  request.post('/api/deleteExistingShoppingCart', data);

export const getDeliverySlotsApi = () => {
  return request.post('/api/getDeliverySlots');
};

export const postDeliverySlotsApi = data =>
  request.post('/api/updateCartShipment', data);

export const postPlaceOrderApi = () => request.post(`/api/v3/orders/new`);

export const postSaveUserPaymentApi = ({ paymentMode, orderNo, data }) =>
  request.post(`/api/orders/payment-methods/V4/${paymentMode}`, data); // COD or POL

export const postSaveBnplPaymentApi = ({ paymentMode, orderNo, data }) =>
  request.post(`/api/orders/payment-methods/V5/${paymentMode}`, data); // COD or POL

export const postSaveMultiModeUserPaymentApi = ({ paymentMode, data }) =>
  request.post(`/api/orders/payment-methods/V6/${paymentMode}`, data); // COD or POL

export const postVerifyPaymentApi = ({ data }) =>
  request.post(`/api/orders/v2/validate-payment`, data);

export const postVerifyPaymentApiPayU = ({ data }) =>
request.post(`/api/orders/v2/validate-payment-payu-app`, data);

export const getOrderConfirmationApi = ({ orderNo }) => {
  return request.get(`/api/v2/order-confirmation/${orderNo}`);
};

export const verifyPayUVpaApi = data =>
  request.post('/api/payment/validateVpa', data);

export const postTransactionInProgressApi = ({tnxId}) => request.post(`/api/transaction-in-progress/${tnxId}`);
